<template>
  <EditPlaceMenu />
</template>

<script>
import EditPlaceMenu from "@/components/places/editPlace/EditPlaceMenu";
import { useStore } from 'vuex'
import {useRoute, useRouter} from "vue-router";
export default {
  name: 'EditVenue',
  components: { EditPlaceMenu },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    store.dispatch('getVenue', route.params.id)
    if(!(store.getters.getId === store.getters.getVenueCreatedBy)) 
        router.push({name: "Places"})

    return { store }
  },

  // beforeRouteLeave (to, from, next) {
  //   this.$store.commit('resetCompany')
  //   next()
  // }
}
</script>


<style scoped>

</style>
