<template>
  <div>PlaceTeam</div>
</template>

<script>
export default {
  name: "PlaceTeam"
}
</script>

<style scoped>

</style>