<template>
  <div class="w-full">
    <main class="grid grid-cols-10">
      <div class="w-full grid col-span-10 bg-white py-8">
        <ProfileHeader :profile="headerInfo"/>
      </div>
      <ProfileNavbar  class="col-start-3 col-span-6" @change="changeComponent" :navigation="navigation"/>

    <div class="col-start-3 col-span-7">
      <transition name="fade">
        <component :is="currentComponent" />
      </transition>
    </div>
    </main>
  </div>
</template>

<script>
import { KeyIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/vue/outline'
import { computed, ref} from "vue";
import { useStore } from "vuex";
import PlaceInfo from "@/components/places/editPlace/PlaceInfo";
import PlaceSettings from "@/components/places/editPlace/PlaceSettings";
import PlaceTeam from "@/components/places/editPlace/PlaceTeam";
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import ProfileHeader from "@/components/widgets/profileHeader/ProfileHeader";


const navigation = ref([
  { name: 'Public Info', onClick:function(){onNavClick(this, navigation.value)}, component: 'PlaceInfo', icon: UserCircleIcon, current: true },
  { name: 'Settings', onClick:function(){onNavClick(this, navigation.value)}, component: 'PlaceSettings', icon: KeyIcon, current: false },
  { name: 'Team', onClick:function(){onNavClick(this, navigation.value)}, component: 'PlaceTeam', icon: UserGroupIcon, current: false },
])

function onNavClick(activeNode, navigation) {
  for(let i=0; i < navigation.length; i++) { navigation[i].current = false }
  activeNode.current = true
}

export default {
  name: 'addPlace',
  components: { PlaceInfo, PlaceSettings, PlaceTeam, ProfileNavbar, ProfileHeader },

  setup() {
    const store = useStore()
    let currentComponent = ref('PlaceInfo')
    return {
      navigation,
      currentComponent,
      changeComponent(component) {
        currentComponent.value = component
      },
      headerInfo: computed(() => store.getters.getVenueHeader),
    }
  },
}
</script>