<template>
  <div class="mt-10 lg:mx-56 sm:mx-10 mx-4">
    <form @submit.prevent="onSubmit">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-primaryDark">
          <div class="">
            <h2 class="text-2xl font-semibold text-gray-900 dark:text-white">Venue</h2>
            <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">This information will be displayed publicly so be careful what you share.</p>
          </div>

          <div class="grid grid-cols-6 gap-6 dark:text-white">
            <div class="col-span-6 sm:col-span-3">
              <label for="venue_website" class="block text-sm font-medium text-gray-700 dark:text-white">
                Venue Name
              </label>
              <div class="mt-1 rounded-md shadow-sm flex">
                <input v-model="state.name" type="text" name="name" id="name" autocomplete="username" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
              </div>
              <VuelidateErrorDisplay :errors="v$.name.$errors" />
            </div>
            <div class="col-span-6 sm:col-span-3 dark:text-white">
              <label for="country" class="block text-sm font-medium text-gray-700 dark:text-white">Venue Type</label>
              <select v-model="state.type" id="type" name="type" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark">
                <option v-for="(venueType, idx) in venueTypes" :key="idx">{{venueType}}</option>
              </select>
              <VuelidateErrorDisplay :errors="v$.type.$errors" />
            </div>
            <div class="col-span-6 dark:text-white">
              <label for="about" class="block text-sm font-medium text-gray-700 dark:text-white">
                Description
              </label>
              <div class="mt-1">
                <textarea v-model="state.description" id="description" name="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-primaryDark" />
              </div>
              <VuelidateErrorDisplay :errors="v$.description.$errors" />
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="country" class="block text-sm font-medium text-gray-700 dark:text-white">Country</label>
              <select v-model="state.country" id="country" name="country" autocomplete="country" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark">
                <option v-for="(country, idx) in countries" :key="idx">{{country}}</option>
              </select>
            </div>
          </div>
          <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5 dark:text-white">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                Contact Information
              </h3>
            </div>
            <div class="space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="first_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-white">
                  Website
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input v-model="state.contact.website" type="text" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md dark:bg-primaryDark" />
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="first_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-white">
                  Email
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input v-model="state.contact.email" type="text" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md dark:bg-primaryDark" />
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="first_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-white">
                  Address
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input v-model="state.contact.address" type="text" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md dark:bg-primaryDark" />
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="first_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-white">
                  Phone
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input v-model="state.contact.phone" type="text" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md dark:bg-primaryDark" />
                </div>
              </div>
            </div>
          </div>
          </div>
        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-primaryDark">
          <button type="submit" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {computed, reactive} from "vue";
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import VuelidateErrorDisplay from "../../widgets/VuelidateErrorDisplay.vue"

export default {
  name: "PlaceInfo",
  components: { VuelidateErrorDisplay },
  setup(){
    const store = useStore()
    const currentVenue = store.getters.getVenue
    const state = reactive({
      name: currentVenue.name,
      description: currentVenue.description,
      country: currentVenue.country,
      type: currentVenue.venueType,
      built: currentVenue.built,
      contact: {
        website: currentVenue.contact.website ? currentVenue.contact.website.url : '',
        email: currentVenue.contact.email ? currentVenue.contact.email.email : '',
        phone: currentVenue.contact.phone ? currentVenue.contact.phone.phone : '',
        address: currentVenue.contact.address ? currentVenue.contact.address.address : '',
      }
    })

    const rules = {
      name: { required },
      type: { required },
      description: { required },
    }
    const v$ = useVuelidate(rules, state, {$lazy: true})


    return {
      v$,
      state,
      store,
      countries: computed(() => store.getters.getCountries),
      venueTypes: computed(() => store.getters.getVenueTypes),
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$error) return
      await this.$store.dispatch('createVenue', this.state)
      await this.$router.push({ name: 'Venue', params: { id: this.$store.getters.getVenueId }})
    }
  }
}

// Logo upload
</script>

<style scoped>

</style>